
import { defineComponent } from "vue";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "kt-widget-3",
  components: {
    Dropdown3
  },
  props: {
    widgetClasses: String
  },
  setup() {
    const items = [
      {
        icon: "media/icons/duotone/Shopping/Cart3.svg",
        color: "success",
        title: "Top Authors",
        description: "Best Customers",
        technologies: "ReactJs, HTML",
        users: "4600 Users",
        size: "5.4MB"
      },
      {
        icon: "media/icons/duotone/Layout/Layout-4-blocks-2.svg",
        color: "danger",
        title: "Popular Authors",
        description: "Best Customers",
        technologies: "Python, MySQL",
        users: "7200 Users",
        size: "2.8MB"
      },
      {
        icon: "media/icons/duotone/Communication/Group.svg",
        color: "info",
        title: "New Users",
        description: "Communication/Group.svg",
        technologies: "Laravel, Future Egg OKR",
        users: "890 Users",
        size: "1.5MB"
      },
      {
        icon: "media/icons/duotone/Home/Library.svg",
        color: "warning",
        title: "Active Customers",
        description: "Best Customers",
        technologies: "AngularJS, C#",
        users: "4600 Users",
        size: "5.4MB"
      },
      {
        icon: "media/icons/duotone/Shopping/Box2.svg",
        color: "primary",
        title: "Active Customers",
        description: "Best Customers",
        technologies: "ReactJS, Ruby",
        users: "354 Users",
        size: "500KB"
      }
    ];

    return {
      items
    };
  }
});

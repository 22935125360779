
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-widget-8",
  components: {},
  props: {
    widgetClasses: String
  },
  setup() {
    const items = [
      [
        {
          icon: {
            file: "media/icons/duotone/Media/Playlist1.svg",
            color: "danger"
          },
          info: {
            event: "School Music Festival",
            user: "by Rose Liam"
          },
          date: "4:20PM, 03 Sep"
        },
        {
          icon: {
            file: "media/icons/duotone/Layout/Layout-4-blocks-2.svg",
            color: "warning"
          },
          info: {
            event: "Maths Championship",
            user: "By Tom Gere"
          },
          date: "10:05PM, 25 Oct"
        },
        {
          icon: {
            file: "media/icons/duotone/Home/Globe.svg",
            color: "info"
          },
          info: {
            event: "Who Knows Geography",
            user: "By Zoey Dylan"
          },
          date: "3:22PM, 07 Sep"
        },
        {
          icon: {
            file: "media/icons/duotone/Home/Library.svg",
            color: "primary"
          },
          info: {
            event: "Napoleon Days",
            user: "By Luke Owen"
          },
          date: "1:20PM, 02 Dec"
        },
        {
          icon: {
            file: "media/icons/duotone/Design/Color-profile.svg",
            color: "success"
          },
          info: {
            event: "The School Art Leads",
            user: "By Ellie Cole"
          },
          date: "6:20PM, 07 Sep"
        }
      ],

      [
        {
          icon: {
            file: "media/icons/duotone/Home/Globe.svg",
            color: "info"
          },
          info: {
            event: "Who Knows Geography",
            user: "By Zoey Dylan"
          },
          date: "3:22PM, 07 Sep"
        },
        {
          icon: {
            file: "media/icons/duotone/Layout/Layout-4-blocks-2.svg",
            color: "warning"
          },
          info: {
            event: "Maths Championship",
            user: "By Tom Gere"
          },
          date: "10:05PM, 25 Oct"
        },
        {
          icon: {
            file: "media/icons/duotone/Media/Playlist1.svg",
            color: "danger"
          },
          info: {
            event: "School Music Festival",
            user: "by Rose Liam"
          },
          date: "4:20PM, 03 Sep"
        }
      ],

      [
        {
          icon: {
            file: "media/icons/duotone/Layout/Layout-4-blocks-2.svg",
            color: "warning"
          },
          info: {
            event: "Maths Championship",
            user: "By Tom Gere"
          },
          date: "10:05PM, 25 Oct"
        },
        {
          icon: {
            file: "media/icons/duotone/Home/Globe.svg",
            color: "info"
          },
          info: {
            event: "Who Knows Geography",
            user: "By Zoey Dylan"
          },
          date: "3:22PM, 07 Sep"
        },
        {
          icon: {
            file: "media/icons/duotone/Home/Library.svg",
            color: "primary"
          },
          info: {
            event: "Napoleon Days",
            user: "By Luke Owen"
          },
          date: "1:20PM, 02 Dec"
        },
        {
          icon: {
            file: "media/icons/duotone/Media/Playlist1.svg",
            color: "danger"
          },
          info: {
            event: "School Music Festival",
            user: "by Rose Liam"
          },
          date: "4:20PM, 03 Sep"
        }
      ]
    ];

    return {
      items
    };
  }
});
